import React from "react";
import styled from "styled-components";
import { FaDiscord, FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";

const FooterContainer = styled.div`
  background-color: #000;
  color: #fff;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 2rem;
  height: max-content;
`;

const FooterText = styled.p`
  text-align: center;
  font-size: 1rem;
  color: #f78e0b;
  margin-top: 1rem;
  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
  @media (max-width: 425px) {
    font-size: 0.6rem;
  }
  @media (max-width: 375px) {
    font-size: 0.5rem;
  }
`;

const FooterLink = styled.a`
  color: #fff;
  text-decoration: none;
`;

const Footer = () => {
  return (
    <FooterContainer id="footer">
      <FooterWrapper>
        <FooterLink href="https://www.facebook.com/ApeArmyGuild">
          <FaFacebook/>
        </FooterLink>
        <FooterLink href="https://twitter.com/ApeArmyGuild">
          <FaTwitter/>
        </FooterLink>
        <FooterLink href="https://instagram.com/apearmyguild">
          <FaInstagram/>
        </FooterLink>
        <FooterLink href="https://discord.gg/apearmyguild">
          <FaDiscord />
        </FooterLink>
      </FooterWrapper>
      <FooterText>
        © 2022 Get Rugged NFT | All Rights Reserved | support@getrugged.io
      </FooterText>
    </FooterContainer>
  );
};

export default Footer;
