import React from "react";
import styled from "styled-components";
import PROF from "../../assets/images/PROF.png";
import SCRAPPY from "../../assets/images/SCRAPPY.png";
import RAINE from "../../assets/images/RAINE.png";
import SYNON from "../../assets/images/SYNON.png";
import YUKI from "../../assets/images/YUKI.png";
import JIE from "../../assets/images/JIE.png";
import FRAME from '../../assets/images/FRAME.png';

const TeamContainer = styled.div`
  color: #fff;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
`;

const TeamWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 350px;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    margin-top: 200px;
  }
  @media (max-width: 500px) {
    margin-top: 100px;
  }
  @media (max-width: 425px) {
    margin-top: 50px;
  }
  @media (max-width: 375px) {
    margin-top: 50px;
  }
`;

const TeamTitle = styled.h1`
  font-size: 2rem;
`;

const TeamBox = styled.div`
  width: 1000px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "one two three" "four five six";
  grid-gap: 2rem;
  padding: 5rem;
  margin-top: 2rem;
  background-image: url(${FRAME});
  background-size: fill;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50px;

  @media (max-width: 1024px) {
    width: 800px;
  }

  @media (max-width: 768px) {
    width: 600px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "one two" "three four" "five six";
  }
  @media (max-width: 650px) {
    width: 500px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "one two" "three four" "five six";
  }

  @media (max-width: 500px) {
    width: 400px;
    grid-template-columns: 1fr;
    grid-template-areas: "one" "two" "three" "four" "five" "six";
  }

  @media (max-width: 425px) {
    width: 300px;
    grid-template-columns: 1fr;
    grid-template-areas: "one" "two" "three" "four" "five" "six";
  }
`;

const TeamCardContainer = styled.div`
  align-items: center;
  justify-content: center;
  max-height: 300px;
`;

const TeamCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const TeamCardImg = styled.img`
  height: 100px;
  width: 100px;
`;

const TeamCardName = styled.h3`
  color: #fff;
`;

const TeamCardPosition = styled.p`
  color: #f78e0b;
  text-align: center;
`;

const TeamCard = ({ src, name, position }) => {
  return (
    <TeamCardContainer>
      <TeamCardWrapper>
        <TeamCardImg src={src} />
        <TeamCardName>{name}</TeamCardName>
        <TeamCardPosition>{position}</TeamCardPosition>
      </TeamCardWrapper>
    </TeamCardContainer>
  );
};

const Team = () => {
  return (
    <TeamContainer id="team">
      <TeamWrapper>
        <TeamTitle>The Team</TeamTitle>
        <TeamBox>
          <TeamCard
            style={{
              gridArea: "one",
            }}
            src={PROF}
            name="Prof"
            position="Founder"
          />
          <TeamCard
            style={{
              gridArea: "two",
            }}
            src={SYNON}
            name="Synon"
            position="Artist"
          />
          <TeamCard
            style={{
              gridArea: "three",
            }}
            src={YUKI}
            name="Yuki"
            position="Art Director"
          />
          <TeamCard
            style={{
              gridArea: "four",
            }}
            src={SCRAPPY}
            name="Scrappy"
            position="PR Director"
          />
          <TeamCard
            style={{
              gridArea: "five",
            }}
            src={RAINE}
            name="Raine"
            position="Marketing Director"
          />
          <TeamCard
            style={{
              gridArea: "six",
            }}
            src={JIE}
            name="Jie"
            position="Developer"
          />
        </TeamBox>
      </TeamWrapper>
    </TeamContainer>
  );
};

export default Team;
