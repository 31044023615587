import React, { useState } from "react";
import styled from "styled-components";
import logo from "../../assets/images/logo.png";
import { Link as LinkS } from "react-scroll";
import { FiMenu } from "react-icons/fi";

// sticky navbar
const Nav = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: transparent;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  z-index: 1;
  @media (max-width: 768px) {
    height: 80px;
  }
  @media (max-width: 500px) {
    height: 60px;
  }
  @media (max-width: 375px) {
    height: 50px;
  }
`;

const Navlink = styled(LinkS)`
  color: #fff;
  text-decoration: none;
  :hover {
    cursor: pointer;
    color: #f78e0b;
  }
  &.active {
    color: #f78e0b;
  }
`;

const NavWrapper = styled.div`
  margin: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
  @media (max-width: 768px) {
    display: none;
  }
`;

const LogoImg = styled.img`
  width: 100px;
  height: 100px;
  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
  @media (max-width: 500px) {
    width: 60px;
    height: 60px;
  }
  @media (max-width: 375px) {
    width: 50px;
    height: 50px;
  }
`;

const ModMenu = styled(FiMenu)`
  color: #fff;
  visibility: hidden;
  @media (max-width: 768px) {
    visibility: visible;
  }
`;

const Navbar = ({ handleClick }) => {
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY > 100) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeBackground);

  return (
    <Nav
      style={{
        backgroundColor: navbar ? "#000" : "transparent",
      }}
    >
      <div>
        <LogoImg src={logo} alt="logo" />
      </div>
      <NavWrapper>
        <Navlink
          to="home"
          spy={true}
          smooth={true}
          duration={500}
          activeClass="active"
        >
          Home
        </Navlink>
        <Navlink
          to="about"
          spy={true}
          smooth={true}
          offset={205}
          duration={500}
          activeClass="active"
        >
          About Us
        </Navlink>
        <Navlink
          to="roadmap"
          spy={true}
          smooth={true}
          offset={200}
          duration={500}
          activeClass="active"
        >
          Roadmap
        </Navlink>
        <Navlink
          to="team"
          spy={true}
          smooth={true}
          offset={200}
          duration={500}
          activeClass="active"
        >
          Team
        </Navlink>
      </NavWrapper>
      <ModMenu onClick={handleClick} />
    </Nav>
  );
};

export default Navbar;
