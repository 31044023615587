import React from "react";
import styled from "styled-components";
import ROADMAP from "../../assets/images/ROADMAP.png";

const RoadmapContainer = styled.div`
  color: #fff;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
`;

const RoadmapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 350px;
  padding: 0 5rem;
  @media (max-width: 768px) {
    margin-top: 200px;
  }
  @media (max-width: 500px) {
    margin-top: 100px;
  }
  @media (max-width: 375px) {
    margin-top: 50px;
  }
`;

const Image = styled.img`
  width: 1000px;
  height: max-content;
  @media (max-width: 1024px) {
    width: 800px;
  }
  @media (max-width: 768px) {
    width: 600px;
  }
  @media (max-width: 500px) {
    width: 400px;
  }
  @media (max-width: 425px) {
    width: 350px;
  }
`;

const RoadmapTitle = styled.h1`
  font-size: 2rem;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
  @media (max-width: 500px) {
    font-size: 1.2rem;
  }
  @media (max-width: 425px) {
    font-size: 1rem;
  }
`;

const Roadmap = () => {
  return (
    <RoadmapContainer id="roadmap">
      <RoadmapWrapper>
        <h1>Roadmap</h1>
        <Image src={ROADMAP} alt="roadmap" />
      </RoadmapWrapper>
    </RoadmapContainer>
  );
};

export default Roadmap;
