import React from "react";
import styled from "styled-components";
import AAG from "../../assets/images/AAG.png";

const AboutContainer = styled.div`
  color: #fff;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
`;

const AboutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 350px;
  @media (max-width: 1024px) {
    margin-top: 150px;
  }
  @media (max-width: 500px) {
    margin-top: 600px;
  }
  @media (max-width: 425px) {
    margin-top: 550px;
  }
  @media (max-width: 375px) {
    margin-top: 500px;
  }
`;

const Image = styled.img`
  width: 250px;
  height: max-content;
  @media (max-width: 768px) {
    width: 200px;
  }
  @media (max-width: 500px) {
    width: 150px;
  }
`;

const AboutDescription = styled.p`
  padding: 0 5rem;
  margin-top: 1rem;
  text-align: center;
  font-size: 1.2rem;
  color: #f78e0b;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
  @media (max-width: 500px) {
    font-size: 0.8rem;
  }
`;

const AboutGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

const AboutCard = styled.div`
  height: max-content;
  padding: 1.5rem;
  width: 500px;
  background-image: radial-gradient(
      circle at 100% 100%,
      transparent 17px,
      #f78e0b 17px,
      #f78e0b 20px,
      transparent 20px
    ),
    linear-gradient(to right, #f78e0b, #000000),
    radial-gradient(
      circle at 0% 100%,
      transparent 17px,
      #000000 17px,
      #000000 20px,
      transparent 20px
    ),
    linear-gradient(to bottom, #000000, #f78e0b),
    radial-gradient(
      circle at 0% 0%,
      transparent 17px,
      #f78e0b 17px,
      #f78e0b 20px,
      transparent 20px
    ),
    linear-gradient(to left, #f78e0b, #000000),
    radial-gradient(
      circle at 100% 0%,
      transparent 17px,
      #000000 17px,
      #000000 20px,
      transparent 20px
    ),
    linear-gradient(to top, #000000, #f78e0b);
  background-size: 20px 20px, calc(100% - 40px) 3px, 20px 20px,
    3px calc(100% - 40px);
  background-position: top left, top center, top right, center right,
    bottom right, bottom center, bottom left, center left;
  background-repeat: no-repeat;
  min-height: 400px;

  @media (max-width: 1024px) {
    width: 350px;
  }
  @media (max-width: 500px) {
    width: 300px;
    min-height: 300px;
  }
`;

const AboutTitle = styled.h1`
  color: #f78e0b;
  font-size: 2.5rem;
  @media (max-width: 650px) {
    font-size: 2rem;
  }
  @media (max-width: 500px) {
    font-size: 1.5rem;
  }
`;

const AboutQoute = styled.p`
  margin-top: 1rem;
  font-size: 1.4rem;
  @media (max-width: 650px) {
    font-size: 1.2rem;
  }
  @media (max-width: 500px) {
    font-size: 1rem;
  }
`;

const About = () => {
  return (
    <AboutContainer id="about">
      <AboutWrapper>
        <Image src={AAG} alt="AAG" />
        <AboutDescription>
          APE ARMY GUILD (AAG) is an esports guild built to capitalize on the
          growth potential of blockchain based play to earn games by educating
          the public to increase adaption and by providing income opportunities
          by lending out gaming assets for profit sharing
        </AboutDescription>
        <AboutGrid>
          <AboutCard>
            <AboutTitle>
              our <br />
              Vision
            </AboutTitle>
            <AboutQoute>
              is to be able to build an Ape Community where game builders, P2E
              managers, players, scholars, NFT collectors, trading coaches,
              investors and traders interact and share knowledge
            </AboutQoute>
          </AboutCard>
          <AboutCard>
            <AboutTitle>
              our <br />
              Mission
            </AboutTitle>
            <AboutQoute>Facilitate launching of AAG Token.</AboutQoute>
            <AboutQoute>
              Facilitate Web3, P2E, NFT and Trading Education.
            </AboutQoute>
            <AboutQoute>
              Assist onboarding of Artists in NFT and Gaming Spaces.
            </AboutQoute>
          </AboutCard>
        </AboutGrid>
      </AboutWrapper>
    </AboutContainer>
  );
};

export default About;
