import "./App.css";
import Navbar from "./components/Navbar";
import HeroSection from "./sections/HeroSection";
import About from "./sections/About";
import Roadmap from "./sections/Roadmap";
import Team from "./sections/Team";
import Footer from "./components/Footer";
import BG from "./assets/images/BG.png";
import Sidebar from "./components/Sidebar";
import { useState } from "react";

function App() {
  const [isShow, setIsShow] = useState(false);

  const handleClick = () => {
    setIsShow(!isShow);
    console.log(isShow);
  };

  return (
    <div
      style={{
        backgroundImage: `url(${BG})`,
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Navbar handleClick={handleClick} />
      <Sidebar isShow={isShow} handleClick={handleClick} />
      <HeroSection />
      <About />
      <Roadmap />
      <Team />
      <Footer />
    </div>
  );
}

export default App;
