import React, { useState } from "react";
import styled from "styled-components";
import { FiX } from "react-icons/fi";
import { Link as LinkS } from "react-scroll";

const SidebarContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1;
  align-items: center;
  justify-content: center;
  top: ${({ isShow }) => (isShow ? "0" : "-100%")};
  opacity: ${({ isShow }) => (isShow ? "100%" : "0")};
  transition: 0.3s ease-in-out;
`;

const SidebarWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

const SidebarLink = styled(LinkS)`
  color: white;
  font-size: 2rem;
`;

const Sidebar = ({ isShow, handleClick }) => {
  const [newShow, setNewShow] = useState(false);
  return (
    <SidebarContainer
      style={{
        visibility: isShow ? "visible" : "hidden",
      }}
      isShow={isShow}
    >
      <SidebarWrapper>
        <SidebarLink
          to="home"
          smooth={true}
          duration={500}
          onClick={handleClick}
          activeClass="active"
        >
          Home
        </SidebarLink>
        <SidebarLink
          to="about"
          smooth={true}
          duration={500}
          onClick={handleClick}
          activeClass="active"
        >
          About
        </SidebarLink>
        <SidebarLink
          to="roadmap"
          smooth={true}
          duration={500}
          onClick={handleClick}
          activeClass="active"
        >
          Roadmap
        </SidebarLink>
        <SidebarLink
          to="team"
          smooth={true}
          duration={500}
          onClick={handleClick}
          activeClass="active"
        >
          Team
        </SidebarLink>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
