/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import styled from "styled-components";
import GR from "../../assets/images/GR.png";
import NFT1 from "../../assets/images/NFT1.png";
import NFT2 from "../../assets/images/NFT2.png";
import NFT3 from "../../assets/images/NFT3.png";
import TAPE from "../../assets/images/TAPE.png";

const HeroContainer = styled.div`
  color: #fff;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const GRImage = styled.img`
  width: 700px;
  height: max-content;
  @media (max-width: 1024px) {
    width: 400px;
  }
  @media (max-width: 768px) {
    width: 300px;
  }
  @media (max-width: 425px) {
    width: 250px;
  }
  @media (max-width: 375px) {
    width: 200px;
  }
`;

const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 500px;
  height: max-content;
  padding: 0 5rem;
  @media (max-width: 1024px) {
    margin-top: 250px;
  }
  @media (max-width: 768px) {
    margin-top: 250px;
  }
  @media (max-width: 650px) {
    margin-top: 250px;
  }
  @media (max-width: 500px) {
    margin-top: 800px;
  }
  @media (max-width: 425px) {
    margin-top: 700px;
  }
  @media (max-width: 375px) {
    margin-top: 600px;
  }
`;

const IntroText = styled.p`
  font-size: 1.5rem;
  margin-top: 2rem;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
  @media (max-width: 425px) {
    font-size: 1rem;
  }
  @media (max-width: 375px) {
    font-size: 0.8rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin-top: 2rem;
  @media (max-width: 768px) {
    flex-direction: row;
  }
  @media (max-width: 425px) {
    gap: 1rem;
    flex-direction: column;
  }
  @media (max-width: 375px) {
    gap: 0.5rem;
    flex-direction: column;
  }
`;

const FillButton = styled.button`
  background-color: #f78e0b;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 50px;
  width: 150px;
  :hover {
    background-color: transparent;
    color: #f78e0b;
    border: 2px solid #f78e0b;
  }
  min-height: 40px;
  transition: all 0.3s ease-in;
`;

const MintButton = styled.button`
  background-color: transparent;
  color: #f78e0b;
  border: 2px solid #f78e0b;
  padding: 0.5rem 1rem;
  border-radius: 50px;
  width: 150px;
  :hover {
    background-color: transparent;
    color: #fff;
    border: 2px solid #fff;
  }
  min-height: 40px;
  transition: all 0.3s ease-in;
`;

const Image = styled.img`
  height: 300px;
  width: 300px;
  @media (max-width: 2560px) {
    width: 300px;
    height: 300px;
  }
  @media (max-width: 1024px) {
    width: 200px;
    height: 200px;
  }
  @media (max-width: 768px) {
    width: 150px;
    height: 150px;
  }
  @media (max-width: 500px) {
    width: 200px;
    height: 200px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  width: 100%;
  justify-content: center;
  gap: 2rem;
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const HeroSection = () => {
  return (
    <HeroContainer id="home">
      <HeroWrapper>
        <GRImage src={GR} alt="logo" />
        <IntroText>
          An opensea collection of CyberSynons built to assist Ape Army Guild in
          accomplishing its vision particularly on educating people in Web 3.0
        </IntroText>
        <ButtonWrapper>
          <FillButton>
            <a href="https://discord.gg/KBm5pnvQVE" style={{ color: "#fff" }}>
              {" "}
              Join Now
            </a>
          </FillButton>
          <MintButton>Coming Soon</MintButton>
        </ButtonWrapper>
        <ImageWrapper>
          <Image src={NFT1} alt="logo" />
          <Image src={NFT2} alt="logo" />
          <Image src={NFT3} alt="logo" />
        </ImageWrapper>
      </HeroWrapper>
      <img
        style={{
          width: "100%",
          objectFit: "cover",
          objectPosition: "center",
          marginTop: "2rem",
        }}
        src={TAPE}
      />
    </HeroContainer>
  );
};

export default HeroSection;
